<template>
  <div>
    <v-container>
      <v-card class="pa-6 my-8">
        <h2 class="section-heading mb-8">Client Details</h2>
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col
                cols="4"
                class="field-label"
              >
                Client Name:
              </v-col>
              <v-col cols="8">{{ client.supplierName }}</v-col>
            </v-row>
            <v-row>
              <v-col
                cols="4"
                class="field-label"
              >
                Client Code:
              </v-col>
              <v-col cols="8">{{ client.supplierCode }}</v-col>
            </v-row>
            <v-row v-if="client.accountId">
              <v-col
                cols="4"
                class="field-label"
              >
                Account ID:
              </v-col>
              <v-col cols="8">{{ client.accountId }}</v-col>
            </v-row>
            <v-row v-if="client.clientId">
              <v-col
                cols="4"
                class="field-label"
              >
                Client ID:
              </v-col>
              <v-col cols="8">{{ client.clientId }}</v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="pa-6 my-8">
        <h2 class="section-heading mb-8">Client Secrets</h2>
        <ads-data-table
          :headers="headers"
          :items="client.secrets"
          item-key="secretId"
        >
          <template v-slot:item.expiryDate="{ item }">
            <v-chip
              :color="item.color"
              textColor="white"
            >
              {{ item.expiryDate }}
            </v-chip>
          </template>
        </ads-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { AdsDataTable } from '@nswdoe/doe-ui-core'

const headers = [
  { text: 'Secret ID', value: 'secretId' },
  { text: 'Secret Hint', value: 'secretHint' },
  { text: 'Expiry Date', value: 'expiryDate' },
  { text: 'Expiry In', value: 'expireDiff' },
]

export default {
  name: 'ViewInstanceSecrets',
  title: 'View Instance Secrets - SAIS Operation UI Portal',
  components: {
    AdsDataTable,
  },
  data() {
    return {
      headers,
      client: {
        accountId: '',
        clientId: '',
        supplierCode: '',
        supplierId: '',
        supplierName: '',
        secrets: [],
      },
    }
  },

  async created() {
    await this.$store.dispatch(
      'moduleInstances/fetchInstanceSecrets',
      this.$route.params.supplierId
    )
    const client = this.$store.state.moduleInstances.instanceSecrets

    if (!client) return
    client.secrets.forEach((secret) => {
      if (!dayjs(secret.expiryDate).isValid()) return

      if (dayjs(secret.expiryDate).isBefore(dayjs())) {
        secret.color = 'red'
      } else {
        secret.color = 'green'
      }
      const days = dayjs(secret.expiryDate).diff(dayjs(), 'day')
      const months = dayjs(secret.expiryDate).diff(dayjs(), 'month')
      const years = dayjs(secret.expiryDate).diff(dayjs(), 'year')

      if (years > 0) {
        secret.expireDiff = `${years} year(s)`
      } else if (months > 0) {
        secret.expireDiff = `${months} month(s)`
      } else if (days > 0) {
        secret.expireDiff = `${days} day(s)`
      } else {
        secret.expireDiff = 'Expired'
      }

      secret.expiryDate = dayjs(secret.expiryDate).format('DD MMM YYYY')
    })
    this.client = client
  },
}
</script>

<style scoped>
.section-heading {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.subsection-heading {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.field-label {
  font-weight: bold;
}
</style>
